import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Agenda_adm from "./agenda_adm";
import Agenda_deposito from "./agenda_deposito";
import Agenda_taller from "./agenda_taller";
import Administrador from "./Administrador";
import stamping from "./stamping";

const App: React.FC = () => {
  /* const navigate = useNavigate();

  useEffect(() => {
    // Obtener la URL actual
    const currentUrl = window.location.pathname;

    // Realizar redirección basada en la URL
    if (currentUrl === "/agenda_adm") {
      // Redirigir a otra página
      navigate("/agenda_adm");
    } else if (currentUrl === "/agenda_taller") {
      // Redirigir a otra página
      navigate("/agenda_taller");
    } else if (currentUrl === "/agenda_deposito") {
      // Redirigir a otra página
      navigate("/agenda_deposito");
    } else if (currentUrl === "/adm") {
      // Redirigir a otra página
      navigate("/adm");
    } else {
      navigate("/adm");
    }
  }, []);*/

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={Administrador} />
        <Route path="/agenda_adm" Component={Agenda_adm} />
        <Route path="/agenda_taller" Component={Agenda_taller} />
        <Route path="/agenda_deposito" Component={Agenda_deposito} />
        <Route path="/adm" Component={Administrador} />
        <Route path="/stamping" Component={stamping} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
