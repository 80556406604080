import * as ReactDOM from 'react-dom';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import './App.css';
import { api } from "./api";

const Stamping = () => {

const Taller: string[] = ['Montevideo', 'Fray Bentos', 'Pueblo Centenario'];
const [mecanicos, setmecanicos] = useState([]);
const [imagen, setimagen] = useState('');

let populateddl: Function = async () => {
    const res = await api.getStamping();
  
console.log(res)


const buffer = res[0].Content.data; // e.g., <Buffer 89 50 4e ... >
const b64 = new Buffer(buffer, 'utf-8').toString('base64');
//const mimeType = some_type // e.g., image/png

//const buffer = res[0].Content.data;
//const base64String = new Buffer(buffer).toString('base64')
//const base64String = btoa(String.fromCharCode(...new Uint8Array()));
   setimagen(b64);
    setmecanicos(res);
    console.log('name', res[0].Content);

    //let url = URL.createObjectURL(res[0].Content.blob);
    //console.log(url);
} 


useEffect(() => {
    return () => {
      populateddl();
    
    };
  }, []);


    return (
        <div>
            <div style={{ justifyContent: 'flex-start', padding: 10 }}>
                <DropDownListComponent id="ddlelement" dataSource={Taller} placeholder="Seleccionar Taller" />
            </div>
            <div className="col-lg-12 control-section" style={{ justifyContent: 'flex-start', paddingTop: 10 }}>
                <div className='control-section card-control-section vertical_card_layout'>
                    <div className="e-card-resize-container">
                        <div className='row'>


                            <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                                <div className="e-card profile" style={{ justifyContent: 'flex-start', backgroundColor: '#fff' }}>
                                    <div className="e-card-header">
                                        <div className="e-avatar e-avatar-xlarge e-avatar-circle">
                                           <img className="image" src={"data:image/png;base64," + imagen} alt="avatar" />
                                            {/* <img className="blob-to-image" src={"data:image/png;base64," + photoBlob}></img> */}
                                        </div>
                                    </div>
                                    <div className="e-card-header">
                                        <div className="e-card-header-caption center">
                                        {/*     <div className="e-card-sub-title">Mecanico</div> */}
                                           {/*  <div className="e-card-header-title">{mecanicos[0].Name}</div>  */}
                                            <div className="e-card-sub-title">Cliente</div>
                                            <div className="e-card-header-title">OPDS</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </div>

    );
}


export default Stamping;