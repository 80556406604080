const API = "http://192.168.0.173:3100/";

const timeout = 15000;

export module api {
  export const obtengoMecanicos = async () => {
    try {
      const init = {
        method: "GET",
        // cache: "no-store",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(API + "mecanicos/mecanicos/", init);

      if (response.status === 500) {
        //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
        return { res: false, message: "Error en el servidor." };
      } else {
        return await response.json();
      }
    } catch (err) {
      console.log(err);
      return { res: false, message: "error" };
    }
  };
  export const obtengoUnidades = async (customer: any) => {
    try {
      const init = {
        method: "GET",
        // cache: "no-store",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(
        API + "customers/unidades/" + customer,
        init
      );

      if (response.status === 500) {
        //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
        return { res: false, message: "Error en el servidor." };
      } else {
        return await response.json();
      }
    } catch (err) {
      console.log(err);
      return { res: false, message: "error" };
    }
  };
  export const obtengoCustomer = async () => {
    try {
      const init = {
        method: "GET",
        // cache: "no-store",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(API + "customers/customers", init);

      if (response.status === 500) {
        //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
        return { res: false, message: "Error en el servidor." };
      } else {
        return await response.json();
      }
    } catch (err) {
      console.log(err);
      return { res: false, message: "error" };
    }
  };

  export const obtengoEventos = async () => {
    try {
      const init = {
        method: "GET",
        // cache: "no-store",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(API + "eventos/eventos", init);

      if (response.status === 500) {
        //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
        return { res: false, message: "Error en el servidor." };
      } else {
        return await response.json();
      }
    } catch (err) {
      console.log(err);
      return { res: false, message: "error" };
    }
  };

  export const createEvent = async (event: Object) => {
    const item = convertir(event);
    try {
      var init = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(item),
      };
      var response = await fetch(API + "eventos/create", init);
      if (response.status === 500) {
        //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
        return { res: false, message: "Error en el servidor." };
      } else {
        return await response.json();
      }
    } catch (err) {
      console.log(err);
      return { res: false, message: "No se pudo crear el evento" };
    }
  };

  export const deleteEvent = async (event: Object) => {
    const item = convertir(event);
    try {
      var init = {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(item),
      };
      var response = await fetch(API + "eventos/" + (event as any).Id, init);
      if (response.status === 500) {
        //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
        return { res: false, message: "Error en el servidor." };
      } else {
        return await response.json();
      }
    } catch (err) {
      console.log(err);
      return { res: false, message: "Error al delete" };
    }
  };

  export const updateEvent = async (event: Object) => {
    const item = convertir(event);
    try {
      var init = {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(item),
      };
      var response = await fetch(API + "eventos/" + (event as any).Id, init);
      if (response.status === 500) {
        //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
        return { res: false, message: "Error en el servidor." };
      } else {
        return await response.json();
      }
    } catch (err) {
      console.log(err);
      return { res: false, message: "Error al update" };
    }
  };

  const convertir = (event: Object) => {
    const item: Record<string, any>[] = [
      {
        idEvento: (event as any).Id,
        title: (event as any).Cliente,
        start: (event as any).StartTime,
        end: (event as any).EndTime,
        TipoCalendario: 0,
        category: (event as any).category,
        isAllDay: false,
        Mecanico: (event as any).MecanicoId,
        Status:
          (event as any).Status === null ? "Nuevo" : (event as any).Status,
        Cliente: (event as any).Cliente,
        Saldo: parseFloat(
          (event as any).Saldo === "" ? 0 : (event as any).Saldo
        ),
        Tope_de_Credito: parseFloat(
          (event as any).Tope_de_Credito === ""
            ? 0
            : (event as any).Tope_de_Credito
        ),
        Mayor_atraso: parseInt(
          (event as any).Mayor_atraso === "" ? 0 : (event as any).Mayor_atraso
        ),
        Box: (event as any).Box,
        Mail: (event as any).Mail,
        telefono: (event as any).telefono,
        Contacto: (event as any).Contacto,
        Kilometraje: (event as any).Kilometraje,
        RecurrenceRule: (event as any).RecurrenceRule,
        IsReadOnly: false,
        Description: (event as any).Description,
        Unidad: (event as any).Unidad,
      },
    ];

    return item;
  };

  export const getStamping = async () => {
    try {
      const init = {
        method: "GET",
        RequestCache: "no-store",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(API + "stamping", init);

      if (response.status === 500) {
        //SL Si el front-End respondio con 500, devuelvo la promesa para que siga la app.
        return { res: false, message: "Error en el servidor." };
      } else {
        return await response.json();
      }
    } catch (err) {
      console.log(err);
      return { res: false, message: "error" };
    }
  };
}
