import * as ReactDOM from "react-dom";
import * as React from "react";
import {
  ButtonComponent,
  SwitchComponent,
  CheckBoxComponent,
  ChangeEventArgs as SwitchEventArgs,
} from "@syncfusion/ej2-react-buttons";
import {
  TimePickerComponent,
  ChangeEventArgs as TimeEventArgs,
  DateTimePickerComponent,
} from "@syncfusion/ej2-react-calendars";
import {
  DropDownListComponent,
  ChangeEventArgs,
  MultiSelectComponent,
  MultiSelectChangeEventArgs,
  CheckBoxSelection,
} from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  ToolbarComponent,
  ItemsDirective,
  ItemDirective,
  BeforeOpenCloseMenuEventArgs,
  MenuEventArgs as ContextMenuEventArgs,
  MenuItemModel,
  ContextMenuComponent,
  ClickEventArgs,
  AppBarComponent,
} from "@syncfusion/ej2-react-navigations";
import {
  ResourcesModel,
  ScheduleComponent,
  RecurrenceEditorComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  Year,
  TimelineViews,
  TimelineMonth,
  TimelineYear,
  ViewsDirective,
  ViewDirective,
  ResourcesDirective,
  ResourceDirective,
  Inject,
  Resize,
  DragAndDrop,
  Agenda,
  Print,
  ExcelExport,
  ICalendarImport,
  ICalendarExport,
  CellClickEventArgs,
  Timezone,
  CurrentAction,
  PopupOpenEventArgs,
  ActionEventArgs,
  EventRenderedArgs,
  ResizeEventArgs,
  DragEventArgs,
} from "@syncfusion/ej2-react-schedule";
import {
  DropDownButtonComponent,
  ItemModel,
  MenuEventArgs,
} from "@syncfusion/ej2-react-splitbuttons";
import {
  addClass,
  Browser,
  closest,
  extend,
  Internationalization,
  isNullOrUndefined,
  removeClass,
  remove,
  compile,
} from "@syncfusion/ej2-base";
import { DataManager, Predicate, Query } from "@syncfusion/ej2-data";
import { tz } from "moment-timezone";
import "./overview.css";
//import EditorTemplate from "./component/editorTemplate";
import { api } from "./api";

function Agenda_deposito(this: any) {
  const [initialEvents, setinitialEvents] = React.useState<
    Record<string, any>[]
  >([]);
  const [mecanicosCollections, setmecanicosCollections] = React.useState<
    Record<string, any>[]
  >([]);
  const [clientesCollections, setclientesCollections] = React.useState<
    Record<string, any>[]
  >([]);

  const [unidadCollections, setunidadCollections] = React.useState<
    Record<string, any>[]
  >([]);
  const [saldo, setSaldo] = React.useState<string>("");
  const [credito, setCredito] = React.useState<string>("");
  // country DropDownList instance
  let customerObj: any;

  // state DropDownList instance
  let unidadObj = React.useRef<DropDownListComponent>(null);
  let textareaObj: TextBoxComponent;
  const [refresh, setRefresh] = React.useState(true);
  const scheduleObj = React.useRef<ScheduleComponent>(null);
  // let scheduleObj: ScheduleComponent;
  let eventTypeObj: DropDownListComponent;
  let saldoObj = React.useRef<TextBoxComponent>(null);
  let recurrObject = React.useRef<RecurrenceEditorComponent>(null);
  let topeCreditoObj = React.useRef<TextBoxComponent>(null);
  let mayorAtrasoObj = React.useRef<TextBoxComponent>(null);

  let notesObj: TextBoxComponent;
  let contextMenuObj: ContextMenuComponent;
  let isTimelineView: boolean = false;
  let selectedTarget: Element;
  let intl: Internationalization = new Internationalization();
  let workWeekObj: MultiSelectComponent;
  let resourceObj: MultiSelectComponent;
  let liveTimeInterval: NodeJS.Timeout | number;

  React.useEffect(() => {
    return () => {
      populateddl();
      generateEvents();

      if (liveTimeInterval) {
        clearInterval(liveTimeInterval as number);
      }
    };
  }, []);

  const eventSettings = {};
  const weekDays: Record<string, any>[] = [
    { text: "Domingo", value: 0 },
    { text: "Lunes", value: 1 },
    { text: "Martes", value: 2 },
    { text: "Miercoles", value: 3 },
    { text: "Jueves", value: 4 },
    { text: "Viernes", value: 5 },
    { text: "Sabado", value: 6 },
  ];

  const contextMenuItems: MenuItemModel[] = [
    { text: "Today", iconCss: "e-icons e-timeline-today", id: "Today" },
    { text: "Edit Event", iconCss: "e-icons e-edit", id: "Save" },
    { text: "Delete Event", iconCss: "e-icons e-trash", id: "Delete" },
    {
      text: "Delete Event",
      id: "DeleteRecurrenceEvent",
      iconCss: "e-icons e-trash",
      items: [
        { text: "Delete Occurrence", id: "DeleteOccurrence" },
        { text: "Delete Series", id: "DeleteSeries" },
      ],
    },
    {
      text: "Edit Event",
      id: "EditRecurrenceEvent",
      iconCss: "e-icons e-edit",
      items: [
        { text: "Edit Occurrence", id: "EditOccurrence" },
        { text: "Edit Series", id: "EditSeries" },
      ],
    },
  ];
  const statusCollections: Record<string, any>[] = [
    { StatusText: "En Trabajo", StatusId: 1, StatusColor: "#008f39" },
    { StatusText: "Entregado", StatusId: 2, StatusColor: "#00aae4" },
    { StatusText: "No se presento", StatusId: 3, StatusColor: "#ff7f50" },
    { StatusText: "Pendiente", StatusId: 4, StatusColor: "#FFFF00" },
    { StatusText: "Autorizado (Contado)", StatusId: 5, StatusColor: "#B5B2B2" },
    { StatusText: "Autorizado (Credito)", StatusId: 6, StatusColor: "#630b57" },
    { StatusText: "Presupuestar", StatusId: 7, StatusColor: "#ff8000" },
    { StatusText: "No autorizado", StatusId: 8, StatusColor: "#FF0000" },
    { StatusText: "Nuevo", StatusId: 9, StatusColor: "#0000FF" },
    { StatusText: "", StatusId: 10, StatusColor: "#0000FF" },
  ];
  const timezoneData: Record<string, any>[] = [
    { text: "UTC -12:00", value: "Etc/GMT+12" },
    { text: "UTC -11:00", value: "Etc/GMT+11" },
    { text: "UTC -10:00", value: "Etc/GMT+10" },
    { text: "UTC -09:00", value: "Etc/GMT+9" },
    { text: "UTC -08:00", value: "Etc/GMT+8" },
    { text: "UTC -07:00", value: "Etc/GMT+7" },
    { text: "UTC -06:00", value: "Etc/GMT+6" },
    { text: "UTC -05:00", value: "Etc/GMT+5" },
    { text: "UTC -04:00", value: "Etc/GMT+4" },
    { text: "UTC -03:00", value: "Etc/GMT+3" },
    { text: "UTC -02:00", value: "Etc/GMT+2" },
    { text: "UTC -01:00", value: "Etc/GMT+1" },
    { text: "UTC +00:00", value: "Etc/GMT" },
    { text: "UTC +01:00", value: "Etc/GMT-1" },
    { text: "UTC +02:00", value: "Etc/GMT-2" },
    { text: "UTC +03:00", value: "Etc/GMT-3" },
    { text: "UTC +04:00", value: "Etc/GMT-4" },
    { text: "UTC +05:00", value: "Etc/GMT-5" },
    { text: "UTC +05:30", value: "Asia/Calcutta" },
    { text: "UTC +06:00", value: "Etc/GMT-6" },
    { text: "UTC +07:00", value: "Etc/GMT-7" },
    { text: "UTC +08:00", value: "Etc/GMT-8" },
    { text: "UTC +09:00", value: "Etc/GMT-9" },
    { text: "UTC +10:00", value: "Etc/GMT-10" },
    { text: "UTC +11:00", value: "Etc/GMT-11" },
    { text: "UTC +12:00", value: "Etc/GMT-12" },
    { text: "UTC +13:00", value: "Etc/GMT-13" },
    { text: "UTC +14:00", value: "Etc/GMT-14" },
  ];
  const majorSlotData: Record<string, any>[] = [
    { Name: "1 hour", Value: 60 },
    { Name: "1.5 hours", Value: 90 },
    { Name: "2 hours", Value: 120 },
    { Name: "2.5 hours", Value: 150 },
    { Name: "3 hours", Value: 180 },
    { Name: "3.5 hours", Value: 210 },
    { Name: "4 hours", Value: 240 },
    { Name: "4.5 hours", Value: 270 },
    { Name: "5 hours", Value: 300 },
    { Name: "5.5 hours", Value: 330 },
    { Name: "6 hours", Value: 360 },
    { Name: "6.5 hours", Value: 390 },
    { Name: "7 hours", Value: 420 },
    { Name: "7.5 hours", Value: 450 },
    { Name: "8 hours", Value: 480 },
    { Name: "8.5 hours", Value: 510 },
    { Name: "9 hours", Value: 540 },
    { Name: "9.5 hours", Value: 570 },
    { Name: "10 hours", Value: 600 },
    { Name: "10.5 hours", Value: 630 },
    { Name: "11 hours", Value: 660 },
    { Name: "11.5 hours", Value: 690 },
    { Name: "12 hours", Value: 720 },
  ];
  const minorSlotData: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const timeFormatData: Record<string, any>[] = [
    { Name: "12 hours", Value: "hh:mm a" },
    { Name: "24 hours", Value: "HH:mm" },
  ];
  const weekNumberData: Record<string, any>[] = [
    { Name: "Off", Value: "Off" },
    { Name: "First Day of Year", Value: "FirstDay" },
    { Name: "First Full Week", Value: "FirstFullWeek" },
    { Name: "First Four-Day Week", Value: "FirstFourDayWeek" },
  ];
  const tooltipData: Record<string, any>[] = [
    { Name: "Off", Value: "Off" },
    { Name: "On", Value: "On" },
  ];

  function updateLiveTime(): void {
    let scheduleTimezone: string = scheduleObj
      ? scheduleObj.current.timezone
      : "Etc/GMT";
    let timeBtn: HTMLElement = document.querySelector(
      ".schedule-overview #timeBtn"
    ) as HTMLElement;
    //console.log("sche", scheduleObj);
    if (timeBtn) {
      let liveTime;
      if (scheduleObj.current.isAdaptive) {
        liveTime = new Date().toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          timeZone: scheduleTimezone,
        });
      } else {
        liveTime = new Date().toLocaleTimeString("en-US", {
          timeZone: scheduleTimezone,
        });
      }
      timeBtn.innerHTML = liveTime;
    }
  }
  let populateddl: Function = async () => {
    const result_mec = await api.obtengoMecanicos();
    // console.log(result_mec);

    const result2_mecanico = result_mec.map(
      (item: { No_: any; Name: any }) => ({
        MecanicoId: item.No_,
        MecanicoText: item.Name,
      })
    );
    setmecanicosCollections(result2_mecanico);

    const result_customer = await api.obtengoCustomer();
    // console.log(result_customer);

    const result2_customer = result_customer.map(
      (item: { No_: any; Name: any; TopeCredito: any; Saldo: any }) => ({
        CustomerId: item.No_,
        CustomerText: item.Name,
        TopeCredito: item.TopeCredito,
        Saldo: item.Saldo,
      })
    );
    setclientesCollections(result2_customer);
    setSaldo(result2_customer.Saldo);
    setCredito(result2_customer.TopeCredito);
    //   console.log("clienteee", (props as any).Cliente);
    //console.log("esto son las props", props);
    /* if ((props as any).Cliente !== "" || (props as any).Cliente !== undefined) {
      const result_un = await api.obtengoUnidades((props as any).Cliente);
      console.log(result_un);

      const result2_un = result_un.map((item: { No_: any; Name: any }) => ({
        UnidadId: item.No_,
        UnidadText: item.Name,
      }));
      setunidadCollections(result2_un);
    }*/
  };

  let generateEvents: Function = async (): Promise<Record<string, any>[]> => {
    let eventData: Record<string, any>[] = [];
    //obtengo los mecanicos primero
    const result_mec = await api.obtengoMecanicos();
    //console.log(result_mec);

    const result2_mecanico = result_mec.map(
      (item: { No_: any; Name: any }) => ({
        MecanicoId: item.No_,
        MecanicoText: item.Name,
      })
    );
    setmecanicosCollections(result2_mecanico);

    const result = await api.obtengoEventos();
    console.log(result);
    const result2 = result.map(
      (item: {
        idEvento: any;
        title: any;
        start: any;
        end: any;
        TipoCalendario: any;
        category: any;
        isAllDay: any;
        Mecanico: any;
        Status: any;
        Cliente: any;
        Saldo: any;
        Tope_de_Credito: any;
        Mayor_atraso: any;
        Box: any;
        Mail: any;
        telefono: any;
        Contacto: any;
        Kilometraje: any;
        RecurrenceRule: any;
        IsReadOnly: any;
        Description: any;
        Unidad: any;
        MecanicoNombre: any;
        ClienteNombre: any;
        Estado: any;
      }) => ({
        Id: item.idEvento,
        Subject: item.title,
        StartTime: new Date(item.start),
        EndTime: new Date(item.end),
        Description: item.Description,
        RecurrenceRule: item.RecurrenceRule,
        IsAllDay: Boolean(item.isAllDay),
        IsReadonly: Boolean(item.IsReadOnly),
        MecanicoId: item.Mecanico,
        MecanicoText: item.MecanicoNombre,
        Kilometraje: item.Kilometraje,
        Contacto: item.Contacto,
        Mail: item.Mail,
        telefono: item.telefono,
        TipoCalendario: item.TipoCalendario,
        category: item.category,
        Status: item.Status,
        Cliente: item.Cliente,
        ClienteText: item.ClienteNombre,
        Box: item.Box,
        Saldo: item.Saldo,
        Tope_de_Credito: item.Tope_de_Credito,
        Mayor_atraso: item.Mayor_atraso,
        Unidad: item.Unidad,
      })
    );
    result2.forEach((element: any) => {
      eventData.push(element);
    });
    let month: number = Math.floor(Math.random() * (11 - 0 + 1) + 0);
    let date: number = Math.floor(Math.random() * (28 - 1 + 1) + 1);
    let hour: number = Math.floor(Math.random() * (23 - 0 + 1) + 0);
    let minutes: number = Math.floor(Math.random() * (59 - 0 + 1) + 0);
    let start: Date = new Date(
      new Date().getFullYear(),
      month,
      date,
      hour,
      minutes,
      0
    );
    let end: Date = new Date(start.getTime());
    end.setHours(end.getHours() + 2);
    let startDate: Date = new Date(start.getTime());
    let endDate: Date = new Date(end.getTime());

    /* if (Browser.isIE) {
      Timezone.prototype.offset = (date: Date, timezone: string): number =>
        tz.zone(timezone).utcOffset(date.getTime());
    }*/
    let overviewEvents: { [key: string]: Date }[] = extend(
      [],
      eventData,
      undefined,
      true
    ) as { [key: string]: Date }[];
    let timezone: Timezone = new Timezone();
    let currentTimezone: never = timezone.getLocalTimezoneName() as never;
    for (let event of overviewEvents) {
      event.StartTime = timezone.convert(
        event.StartTime,
        "Etc/GMT+3",
        currentTimezone
      );
      event.EndTime = timezone.convert(
        event.EndTime,
        "Etc/GMT+3",
        currentTimezone
      );
    }
    // console.log("evetos finales:", overviewEvents);
    setinitialEvents(overviewEvents);
    //setRefresh(false);
    return overviewEvents;
  };

  function getEventData(): Record<string, any> {
    const date: Date = scheduleObj.current.selectedDate;
    return {
      Id: scheduleObj.current.getEventMaxID(),
      Subject: "",
      StartTime: new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        new Date().getHours(),
        0,
        0
      ),
      EndTime: new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        new Date().getHours() + 1,
        0,
        0
      ),
      Description: "",
      IsAllDay: false,
      StatusId: 1,
    };
  }

  function onToolbarItemClicked(args: ClickEventArgs): void {
    switch (args.item.text) {
      case "Day":
        scheduleObj.current.currentView = isTimelineView
          ? "TimelineDay"
          : "Day";
        break;
      case "Week":
        scheduleObj.current.currentView = isTimelineView
          ? "TimelineWeek"
          : "Week";
        break;
      case "WorkWeek":
        scheduleObj.current.currentView = isTimelineView
          ? "TimelineWorkWeek"
          : "WorkWeek";
        break;
      case "Month":
        scheduleObj.current.currentView = isTimelineView
          ? "TimelineMonth"
          : "Month";
        break;
      case "Year":
        scheduleObj.current.currentView = isTimelineView
          ? "TimelineYear"
          : "Year";
        break;
      case "Agenda":
        scheduleObj.current.currentView = "Agenda";
        break;
    }
  }

  function timelineTemplate(): JSX.Element {
    return (
      <div className="template">
        <div className="icon-child">
          <CheckBoxComponent
            id="timeline_views"
            checked={false}
            change={(args: SwitchEventArgs) => {
              isTimelineView = args.checked as boolean;
              switch (scheduleObj.current.currentView) {
                case "Day":
                case "TimelineDay":
                  scheduleObj.current.currentView = isTimelineView
                    ? "TimelineDay"
                    : "Day";
                  break;
                case "Week":
                case "TimelineWeek":
                  scheduleObj.current.currentView = isTimelineView
                    ? "TimelineWeek"
                    : "Week";
                  break;
                case "WorkWeek":
                case "TimelineWorkWeek":
                  scheduleObj.current.currentView = isTimelineView
                    ? "TimelineWorkWeek"
                    : "WorkWeek";
                  break;
                case "Month":
                case "TimelineMonth":
                  scheduleObj.current.currentView = isTimelineView
                    ? "TimelineMonth"
                    : "Month";
                  break;
                case "Year":
                case "TimelineYear":
                  scheduleObj.current.currentView = isTimelineView
                    ? "TimelineYear"
                    : "Year";
                  break;
                case "Agenda":
                  scheduleObj.current.currentView = "Agenda";
                  break;
              }
            }}
          />
        </div>
        <div className="text-child">Timeline Views</div>
      </div>
    );
  }

  function groupTemplate(): JSX.Element {
    return (
      <div className="template">
        <div className="icon-child">
          <CheckBoxComponent
            id="grouping"
            checked={true}
            change={(args: SwitchEventArgs) => {
              scheduleObj.current.group.resources = args.checked
                ? ["Mecanicos"]
                : [];
            }}
          />
        </div>
        <div className="text-child">Grouping</div>
      </div>
    );
  }

  function gridlineTemplate(): JSX.Element {
    return (
      <div className="template">
        <div className="icon-child">
          <CheckBoxComponent
            id="timeSlots"
            checked={true}
            change={(args: SwitchEventArgs) => {
              scheduleObj.current.timeScale.enable = args.checked as boolean;
            }}
          />
        </div>
        <div className="text-child">Gridlines</div>
      </div>
    );
  }

  function autoHeightTemplate(): JSX.Element {
    return (
      <div className="template">
        <div className="icon-child">
          <CheckBoxComponent
            id="row_auto_height"
            checked={false}
            change={(args: SwitchEventArgs) => {
              scheduleObj.current.rowAutoHeight = args.checked as boolean;
            }}
          />
        </div>
        <div className="text-child">Row Auto Height</div>
      </div>
    );
  }

  function getDateHeaderDay(value: Date): string {
    return intl.formatDate(value, { skeleton: "E" });
  }
  function getDateHeaderDate(value: Date): string {
    return intl.formatDate(value, { skeleton: "d" });
  }
  function getWeather(value: Date) {
    switch (value.getDay()) {
      case 0:
        return '<img class="weather-image"  src= "src/schedule/images/weather-clear.svg" />';
      case 1:
        return '<img class="weather-image" src="src/schedule/images/weather-clouds.svg"/>';
      case 2:
        return '<img class="weather-image" src="src/schedule/images/weather-rain.svg"/>';
      case 3:
        return '<img class="weather-image" src="src/schedule/images/weather-clouds.svg"/>';
      case 4:
        return '<img class="weather-image" src="src/schedule/images/weather-rain.svg"/>';
      case 5:
        return '<img class="weather-image" src="src/schedule/images/weather-clear.svg"/>';
      case 6:
        return '<img class="weather-image" src="src/schedule/images/weather-clouds.svg"/>';
      default:
        return null;
    }
  }

  function dateHeaderTemplate(props: { date: Date }): JSX.Element {
    return (
      <div>
        <div>{getDateHeaderDay(props.date)}</div>
        <div>{getDateHeaderDate(props.date)}</div>
      </div>
    );
  }

  function onResourceChange(args: MultiSelectChangeEventArgs): void {
    let resourcePredicate: Predicate & any;
    for (let value of args.value) {
      if (resourcePredicate) {
        resourcePredicate = resourcePredicate.or(
          new Predicate("MecanicoId", "equal", value)
        );
      } else {
        resourcePredicate = new Predicate("MecanicoId", "equal", value);
      }
    }
    scheduleObj.current.resources[0].query = resourcePredicate
      ? new Query().where(resourcePredicate)
      : new Query();
  }

  const onPopupOpen = async (args: PopupOpenEventArgs) => {
    console.log("se abre popup", args);
    if (["QuickInfo", "Editor"].indexOf(args.type) > -1) {
      args.cancel = true;
    }
    if (args.type === "Editor") {
      (scheduleObj.current.eventWindow as any).recurrenceEditor =
        recurrObject.current;
      const data = clientesCollections.filter(
        (cust) => cust.CustomerId === args.data.Cliente
      );
      if ((args.data as any).Saldo !== undefined) {
        (args.data as any).Saldo = (data as any)[0].Saldo;
        (args.data as any).Tope_de_Credito = (data as any)[0].TopeCredito;
        //Seteo la data para el ddl Unidad

        const result_un = await api.obtengoUnidades(args.data.Cliente);
        //   console.log(result_un);

        const result2_un = result_un[0].map(
          (item: { "Item No_": any; "Serial No_": any }) => ({
            UnidadId: item["Serial No_"],
            UnidadText: item["Item No_"],
          })
        );
        //  setunidadCollections(result2_un);
        unidadObj.current.dataSource = result2_un;
      }
      // saldoObj.current.value = "500"; //(data as any).Saldo;
      // topeCreditoObj.current.value = "500"; // (data as any).TopeCredito;
      /* let mecanicoElement: HTMLInputElement = args.element.querySelector(
        "input#Saldo"
      ) as HTMLInputElement;
      console.log(mecanicoElement);
      if (mecanicoElement) {
        mecanicoElement.setAttribute("value", "500");
      }*/
    }
  };
  const editorTemplate = (props: Object): JSX.Element => {
    return props !== undefined ? (
      <table className="custom-event-editor" style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td className="e-textlabel" style={{ width: "10%" }}>
              Cliente
            </td>
            <td style={{ width: "40%" }}>
              <DropDownListComponent
                id="Cliente"
                placeholder="Seleccionar Cliente"
                data-name="Cliente"
                className="e-field"
                ref={(scope) => {
                  customerObj = scope;
                }}
                dataSource={clientesCollections as Record<string, any>[]}
                fields={{ text: "CustomerText", value: "CustomerId" }}
                value={(props as any).Cliente || null}
                change={onCustomerChange.bind(this)}
              ></DropDownListComponent>
            </td>
            <td
              className="e-textlabel"
              style={{ width: "10%", paddingLeft: "10px" }}
            >
              Tipo Trabajo
            </td>
            <td>
              <DropDownListComponent
                id="category"
                placeholder="Seleccione Tipo Trabajo"
                data-name="category"
                className="e-field"
                dataSource={["Hora Reparacion", "Hora Diagnostico"]}
                value={(props as any).category || null}
              ></DropDownListComponent>
            </td>
          </tr>
          <tr>
            <td className="e-textlabel" style={{ width: "10%" }}>
              Saldo
            </td>
            <td style={{ width: "40%" }}>
              <TextBoxComponent
                readOnly={true}
                id="Saldo"
                className="e-field e-input"
                type="text"
                name="Saldo"
                ref={saldoObj}
                value={(props as any).Saldo}
              />
            </td>
            <td
              className="e-textlabel"
              style={{ width: "10%", paddingLeft: "10px" }}
            >
              Desde
            </td>
            <td>
              <DateTimePickerComponent
                format="dd/MM/yy hh:mm a"
                id="StartTime"
                data-name="StartTime"
                value={
                  new Date((props as any).startTime || (props as any).StartTime)
                }
                className="e-field"
              ></DateTimePickerComponent>
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">Tope de credito</td>
            <td>
              <TextBoxComponent
                readOnly={true}
                id="Tope_de_Credito"
                className="e-field e-input"
                type="text"
                name="Tope_de_Credito"
                ref={topeCreditoObj}
                value={(props as any).Tope_de_Credito}
              />
            </td>
            <td
              className="e-textlabel"
              style={{ width: "10%", paddingLeft: "10px" }}
            >
              Hasta
            </td>
            <td>
              <DateTimePickerComponent
                format="dd/MM/yy hh:mm a"
                id="EndTime"
                data-name="EndTime"
                value={
                  new Date((props as any).endTime || (props as any).EndTime)
                }
                className="e-field"
              ></DateTimePickerComponent>
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">Mayor atraso</td>
            <td>
              <TextBoxComponent
                readOnly={true}
                id="Mayor_atraso"
                className="e-field e-input"
                type="text"
                name="Mayor_atraso"
                ref={mayorAtrasoObj}
                value={(props as any).Mayor_atraso}
              />
            </td>
            <td
              className="e-textlabel"
              style={{ width: "10%", paddingLeft: "10px" }}
            >
              Box
            </td>
            <td>
              <DropDownListComponent
                id="Box"
                placeholder="Seleccionar Box"
                data-name="Box"
                className="e-field"
                dataSource={["Box 1", "Box 2", "Box 3"]}
                value={(props as any).Box || null}
              ></DropDownListComponent>
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">Mail</td>
            <td>
              <TextBoxComponent
                id="Mail"
                className="e-field e-input"
                type="text"
                name="Mail"
                value={(props as any).Mail || null}
              />
            </td>
            <td
              className="e-textlabel"
              style={{ width: "10%", paddingLeft: "10px" }}
            >
              Mecanico
            </td>
            <td>
              <DropDownListComponent
                id="MecanicoId"
                placeholder="Seleccionar Mecanico"
                data-name="MecanicoId"
                className="e-field"
                fields={{ text: "MecanicoText", value: "MecanicoId" }}
                dataSource={mecanicosCollections as Record<string, any>[]}
                value={(props as any).MecanicoId || null}
              ></DropDownListComponent>
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">Contacto</td>
            <td>
              <TextBoxComponent
                id="Contacto"
                className="e-field e-input"
                type="text"
                name="Contacto"
                value={(props as any).Contacto || null}
              />
            </td>
            <td
              className="e-textlabel"
              style={{ width: "10%", paddingLeft: "10px" }}
            >
              Unidad
            </td>
            <td>
              <DropDownListComponent
                id="Unidad"
                placeholder="Seleccionar Unidad"
                data-name="Unidad"
                className="e-field"
                ref={unidadObj}
                fields={{ text: "UnidadText", value: "UnidadText" }}
                dataSource={unidadCollections as Record<string, any>[]}
                value={(props as any).Unidad || null}
              ></DropDownListComponent>
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">Telefono</td>
            <td>
              <TextBoxComponent
                id="telefono"
                className="e-field e-input"
                type="text"
                name="telefono"
                value={(props as any).telefono || null}
              />
            </td>
            <td
              className="e-textlabel"
              style={{ width: "10%", paddingLeft: "10px" }}
            >
              Kilometraje
            </td>
            <td>
              <TextBoxComponent
                readOnly={true}
                id="Kilometraje"
                className="e-field e-input"
                type="text"
                name="Kilometraje"
                value={(props as any).Kilometraje || null}
              />
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">Descripcion</td>
            <td colSpan={4}>
              <textarea
                rows={3}
                cols={10}
                id="Description"
                className="e-field e-input"
                name="Description"
                value={(props as any).Description || null}
              />
            </td>
          </tr>
          <tr>
            <td>
              <input
                id="Status"
                className="e-field e-input"
                type="text"
                name="Status"
                hidden={true}
                value={(props as any).Status || null}
              />
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">Recurrence</td>
            <td colSpan={4}>
              <RecurrenceEditorComponent
                ref={recurrObject}
                id="RecurrenceEditor"
              />
            </td>
          </tr>
        </tbody>
      </table>
    ) : (
      <div></div>
    );
  };
  let onCustomerChange: Function = async (
    args: ChangeEventArgs,
    props: Object
  ) => {
    // console.log("cambioo el ddl", args);
    // console.log("cambio props", props);

    if (args.itemData !== null) {
      saldoObj.current.value = (args.itemData as any).Saldo;
      topeCreditoObj.current.value = (args.itemData as any).TopeCredito;
      mayorAtrasoObj.current.value = "0";
      // textareaObj.value = (args.itemData as any).TopeCredito;
      //  (props as any).TopeCredito = (args.itemData as any).TopeCredito;
      // (props as any).Saldo = (args.itemData as any).Saldo;
      const result_un = await api.obtengoUnidades(
        (args.itemData as any).CustomerId
      );
      //  console.log(result_un);

      const result2_un = result_un[0].map(
        (item: { "Item No_": any; "Serial No_": any }) => ({
          UnidadId: item["Serial No_"],
          UnidadText: item["Item No_"],
        })
      );
      //  setunidadCollections(result2_un);
      unidadObj.current.dataSource = result2_un;
    }
  };

  const onDragStart = (args: DragEventArgs): void => {
    args.cancel = true;
  };
  const onResizeStart = (args: ResizeEventArgs): void => {
    args.cancel = true;
  };
  const onActionComplete = (args: ActionEventArgs): void => {
    //  console.log(args);
    if (args.requestType === "toolBarItemRendered") {
      // This block is execute after toolbarItem render
    }
    if (args.requestType === "dateNavigate") {
      // This block is executed after previous and next navigation
    }
    if (args.requestType === "viewNavigate") {
      // This block is execute after view navigation
    }
    if (args.requestType === "eventCreated") {
      // This block is execute after an appointment create
      console.log("cree uno nuevo", args);
      args.cancel = true;
      if (isNullOrUndefined(args.addedRecords[0].RecurrenceRule)) {
        args.addedRecords[0].RecurrenceRule = "";
      }
      if (isNullOrUndefined(args.addedRecords[0].Unidad)) {
        args.addedRecords[0].Unidad = "";
      }
      //busco id cliente y id mecanico
      const data = clientesCollections.filter(
        (cust) => cust.CustomerId === args.addedRecords[0].Cliente
      );
      //args.changedRecords[0].ClienteNombre = args.changedRecords[0].Cliente;
      args.addedRecords[0].ClienteNombre = (data as any)[0].ClienteText;
      const dataMec = mecanicosCollections.filter(
        (mec) => mec.MecanicoId === args.addedRecords[0].MecanicoId
      );
      args.addedRecords[0].MecanicoNombre = (dataMec as any)[0].MecanicoText;
      api.createEvent(args.addedRecords[0]);
    }
    if (args.requestType === "eventChanged") {
      args.cancel = true;
      // This block is execute after an appointment change
      console.log("modifique uno", args);
      if (isNullOrUndefined(args.changedRecords[0].RecurrenceRule)) {
        args.changedRecords[0].RecurrenceRule = "";
      }
      if (isNullOrUndefined(args.changedRecords[0].Unidad)) {
        args.changedRecords[0].Unidad = "";
      }
      //busco id cliente y id mecanico
      const data = clientesCollections.filter(
        (cust) => cust.CustomerId === args.changedRecords[0].Cliente
      );
      //args.changedRecords[0].ClienteNombre = args.changedRecords[0].Cliente;
      args.changedRecords[0].ClienteNombre = (data as any)[0].ClienteText;
      const dataMec = mecanicosCollections.filter(
        (mec) => mec.MecanicoId === args.changedRecords[0].MecanicoId
      );
      args.changedRecords[0].MecanicoNombre = (dataMec as any)[0].MecanicoText;

      api.updateEvent(args.changedRecords[0]);
    }
    if (args.requestType === "eventRemoved") {
      args.cancel = true;
      // This block is execute after an appointment remove
      console.log("elimine uno", args);
      if (args.deletedRecords[0].RecurrenceRule === undefined) {
        args.deletedRecords[0].RecurrenceRule = "";
      }
      if (args.deletedRecords[0].Unidad === null) {
        args.deletedRecords[0].Unidad = "";
      }
      api.deleteEvent(args.deletedRecords[0]);
    }
  };
  const getTime = (data: { [key: string]: string }): string => {
    return (
      intl.formatDate(new Date(data.StartTime), { skeleton: "hm" }) +
      " - " +
      intl.formatDate(new Date(data.EndTime), { skeleton: "hm" })
    );
  };
  function onEventRendered(args: EventRenderedArgs): void {
    const data = statusCollections.filter(
      (status) => status.StatusText === args.data.Status
    );
    (args.element as HTMLElement).style.backgroundColor = (
      data as any
    )[0].StatusColor;
  }
  const templateEvent = (props: { [key: string]: string }): JSX.Element => {
    //console.log(props);
    const data = statusCollections.filter(
      (status) => status.StatusText === props.Status
    );
    const cliente = clientesCollections.filter(
      (cust) => cust.CustomerId === props.Cliente
    );
    let cli = "";
    if (cliente.length === 0) {
      cli = "";
    } else {
      cli = cliente[0].CustomerText;
    }

    return (
      <div className="e-appointment-details">
        <div className="e-subject">{cli}</div>
        <div className="e-time">{getTime(props)}</div>
      </div>
    );
  };

  return (
    <div className="schedule-control-section">
      <div className="col-lg-12 control-section">
        <div className="content-wrapper">
          <div className="schedule-overview">
            <AppBarComponent colorMode="Primary">
              <span className="time e-icons e-time-zone"></span>
              <span id="timezoneBtn" className="time ">
                UTC -3
              </span>
              <span className="time e-icons e-clock"></span>
              <span id="timeBtn" className="time current-time">
                Time
              </span>
              <div className="e-appbar-spacer"></div>
              {/* <div className="control-panel calendar-export">
                <ButtonComponent
                  id="printBtn"
                  cssClass="title-bar-btn e-inherit"
                  iconCss="e-icons e-print"
                  onClick={onPrint.bind(this)}
                  content="Print"
                />
              </div>

              <div className="control-panel calendar-export">
                <DropDownButtonComponent
                  id="exportBtn"
                  content="Export"
                  cssClass="e-inherit"
                  items={exportItems}
                  select={onExportClick.bind(this)}
                />
  </div> */}
              <ButtonComponent
                id="settingsBtn"
                cssClass="overview-toolbar-settings e-inherit"
                iconCss="e-icons e-settings"
                iconPosition="Top"
                content=""
                onClick={() => {
                  let settingsPanel: Element = document.querySelector(
                    ".overview-content .right-panel"
                  ) as Element;
                  if (settingsPanel.classList.contains("hide")) {
                    removeClass([settingsPanel], "hide");
                    workWeekObj.refresh();
                    resourceObj.refresh();
                  } else {
                    addClass([settingsPanel], "hide");
                  }
                  scheduleObj.current.refreshEvents();
                }}
              />
            </AppBarComponent>
            <ToolbarComponent
              id="toolbarOptions"
              cssClass="overview-toolbar"
              width="100%"
              height={70}
              overflowMode="Scrollable"
              scrollStep={100}
              created={() =>
                (liveTimeInterval = setInterval(() => {
                  updateLiveTime();
                }, 10000000))
              }
              clicked={onToolbarItemClicked.bind(this)}
            >
              <ItemsDirective>
                <ItemDirective
                  prefixIcon="e-icons e-day"
                  tooltipText="Day"
                  text="Day"
                  tabIndex={0}
                />
                <ItemDirective
                  prefixIcon="e-icons e-week"
                  tooltipText="Week"
                  text="Week"
                  tabIndex={0}
                />
                <ItemDirective
                  prefixIcon="e-icons e-week"
                  tooltipText="WorkWeek"
                  text="WorkWeek"
                  tabIndex={0}
                />
                <ItemDirective
                  prefixIcon="e-icons e-month"
                  tooltipText="Month"
                  text="Month"
                  tabIndex={0}
                />
                <ItemDirective
                  prefixIcon="e-icons e-month"
                  tooltipText="Year"
                  text="Year"
                  tabIndex={0}
                />
                <ItemDirective
                  prefixIcon="e-icons e-agenda-date-range"
                  tooltipText="Agenda"
                  text="Agenda"
                  tabIndex={0}
                />
                <ItemDirective
                  tooltipText="Timeline Views"
                  text="Timeline Views"
                  template={timelineTemplate.bind(this)}
                />
                <ItemDirective type="Separator" />
                <ItemDirective
                  tooltipText="Grouping"
                  text="Grouping"
                  template={groupTemplate.bind(this)}
                />
                <ItemDirective
                  tooltipText="Timme Slots"
                  text="Timme Slots"
                  template={gridlineTemplate.bind(this)}
                />
                <ItemDirective
                  tooltipText="Auto Fit Rows"
                  text="Auto Fit Rows"
                  template={autoHeightTemplate.bind(this)}
                />
              </ItemsDirective>
            </ToolbarComponent>
            <div className="overview-content">
              <div className="left-panel">
                <div className="overview-scheduler">
                  <ScheduleComponent
                    id="scheduler"
                    cssClass="schedule-overview"
                    ref={scheduleObj}
                    width="100%"
                    height="100%"
                    group={{ resources: ["Mecanicos"] }}
                    timezone="Etc/GMT+3"
                    dragStart={onDragStart.bind(this)}
                    resizeStart={onResizeStart.bind(this)}
                    eventSettings={{
                      dataSource: initialEvents,
                      template: templateEvent as any,
                    }}
                    eventRendered={onEventRendered}
                    dateHeaderTemplate={dateHeaderTemplate.bind(this)}
                    editorTemplate={editorTemplate}
                    actionComplete={onActionComplete.bind(this)}
                    showQuickInfo={false}
                    popupOpen={onPopupOpen.bind(this)}
                  >
                    <ResourcesDirective>
                      <ResourceDirective
                        field="MecanicoId"
                        title="Mecanico"
                        name="Mecanicos"
                        dataSource={mecanicosCollections}
                        textField="MecanicoText"
                        idField="MecanicoId"
                      ></ResourceDirective>
                    </ResourcesDirective>
                    <ViewsDirective>
                      <ViewDirective option="Day" />
                      <ViewDirective option="Week" />
                      <ViewDirective option="WorkWeek" />
                      <ViewDirective option="Month" />
                      <ViewDirective option="Year" />
                      <ViewDirective option="Agenda" />
                      <ViewDirective option="TimelineDay" />
                      <ViewDirective option="TimelineWeek" />
                      <ViewDirective option="TimelineWorkWeek" />
                      <ViewDirective option="TimelineMonth" />
                      <ViewDirective option="TimelineYear" />
                    </ViewsDirective>
                    <Inject
                      services={[
                        Day,
                        Week,
                        WorkWeek,
                        Month,
                        Year,
                        Agenda,
                        TimelineViews,
                        TimelineMonth,
                        TimelineYear,
                        DragAndDrop,
                        Resize,
                        Print,
                        ExcelExport,
                        ICalendarImport,
                        ICalendarExport,
                      ]}
                    />
                  </ScheduleComponent>
                  <ContextMenuComponent
                    id="overviewContextMenu"
                    cssClass="schedule-context-menu"
                    ref={(menu: ContextMenuComponent) =>
                      (contextMenuObj = menu)
                    }
                    target=".e-schedule"
                    items={contextMenuItems}
                    beforeOpen={(args: BeforeOpenCloseMenuEventArgs) => {
                      let newEventElement: HTMLElement = document.querySelector(
                        ".e-new-event"
                      ) as HTMLElement;
                      if (newEventElement) {
                        remove(newEventElement);
                        removeClass(
                          [
                            document.querySelector(
                              ".e-selected-cell"
                            ) as Element,
                          ],
                          "e-selected-cell"
                        );
                      }
                      scheduleObj.current.closeQuickInfoPopup();
                      let targetElement: HTMLElement = args.event
                        .target as HTMLElement;
                      if (closest(targetElement, ".e-contextmenu")) {
                        return;
                      }
                      selectedTarget = closest(
                        targetElement,
                        ".e-appointment,.e-work-cells,.e-vertical-view .e-date-header-wrap .e-all-day-cells,.e-vertical-view .e-date-header-wrap .e-header-cells"
                      );
                      if (isNullOrUndefined(selectedTarget)) {
                        args.cancel = true;
                        return;
                      }
                      if (selectedTarget.classList.contains("e-appointment")) {
                        let eventObj: Record<string, any> =
                          scheduleObj.current.getEventDetails(
                            selectedTarget
                          ) as Record<string, any>;
                        if (eventObj.RecurrenceRule) {
                          contextMenuObj.showItems(
                            ["EditRecurrenceEvent", "DeleteRecurrenceEvent"],
                            true
                          );
                          contextMenuObj.hideItems(
                            ["Add", "AddRecurrence", "Today", "Save", "Delete"],
                            true
                          );
                        } else {
                          contextMenuObj.showItems(["Save", "Delete"], true);
                          contextMenuObj.hideItems(
                            [
                              "Add",
                              "AddRecurrence",
                              "Today",
                              "EditRecurrenceEvent",
                              "DeleteRecurrenceEvent",
                            ],
                            true
                          );
                        }
                        return;
                      } else if (
                        (selectedTarget.classList.contains("e-work-cells") ||
                          selectedTarget.classList.contains(
                            "e-all-day-cells"
                          )) &&
                        !selectedTarget.classList.contains("e-selected-cell")
                      ) {
                        removeClass(
                          [].slice.call(
                            scheduleObj.current.element.querySelectorAll(
                              ".e-selected-cell"
                            )
                          ),
                          "e-selected-cell"
                        );
                        selectedTarget.setAttribute("aria-selected", "true");
                        selectedTarget.classList.add("e-selected-cell");
                      }
                      contextMenuObj.hideItems(
                        [
                          "Save",
                          "Delete",
                          "EditRecurrenceEvent",
                          "DeleteRecurrenceEvent",
                        ],
                        true
                      );
                      contextMenuObj.showItems(
                        ["Add", "AddRecurrence", "Today"],
                        true
                      );
                    }}
                    select={(args: ContextMenuEventArgs) => {
                      let selectedMenuItem: string = args.item.id as string;
                      let eventObj: Record<string, any> = {};
                      if (
                        selectedTarget &&
                        selectedTarget.classList.contains("e-appointment")
                      ) {
                        eventObj = scheduleObj.current.getEventDetails(
                          selectedTarget
                        ) as Record<string, any>;
                      }
                      switch (selectedMenuItem) {
                        case "Today":
                          scheduleObj.current.selectedDate = new Date();
                          break;
                        case "Add":
                        case "AddRecurrence":
                          let selectedCells: Element[] =
                            scheduleObj.current.getSelectedElements();
                          let activeCellsData: CellClickEventArgs =
                            scheduleObj.current.getCellDetails(
                              selectedCells.length > 0
                                ? selectedCells
                                : selectedTarget
                            );
                          if (selectedMenuItem === "Add") {
                            console.log("entre al ADD!");
                            scheduleObj.current.openEditor(
                              activeCellsData,
                              "Add"
                            );
                          } else {
                            scheduleObj.current.openEditor(
                              activeCellsData,
                              "Add",
                              false,
                              1
                            );
                          }
                          break;
                        case "Save":
                        case "EditOccurrence":
                        case "EditSeries":
                          if (selectedMenuItem === "EditSeries") {
                            let query: Query = new Query().where(
                              scheduleObj.current.eventFields.id as string,
                              "equal",
                              eventObj.RecurrenceID as string | number
                            );
                            eventObj = new DataManager(
                              scheduleObj.current.eventsData
                            ).executeLocal(query)[0] as Record<string, any>;
                          }
                          scheduleObj.current.openEditor(
                            eventObj,
                            selectedMenuItem
                          );
                          break;
                        case "Delete":
                          scheduleObj.current.deleteEvent(eventObj);
                          break;
                        case "DeleteOccurrence":
                        case "DeleteSeries":
                          scheduleObj.current.deleteEvent(
                            eventObj,
                            selectedMenuItem
                          );
                          break;
                      }
                    }}
                  ></ContextMenuComponent>
                </div>
              </div>
              <div className="right-panel hide">
                <div className="control-panel e-css">
                  <div className="col-row">
                    <div className="col-left">
                      <label style={{ lineHeight: "34px", margin: "0" }}>
                        Empleados
                      </label>
                    </div>
                    <div className="col-right">
                      <MultiSelectComponent
                        id="resources"
                        cssClass="schedule-resource"
                        ref={(resources: MultiSelectComponent) =>
                          (resourceObj = resources)
                        }
                        dataSource={
                          mecanicosCollections as Record<string, any>[]
                        }
                        mode="CheckBox"
                        fields={{ text: "MecanicoText", value: "MecanicoId" }}
                        enableSelectionOrder={false}
                        showClearButton={false}
                        showDropDownIcon={true}
                        popupHeight={300}
                        change={onResourceChange.bind(this)}
                      >
                        <Inject services={[CheckBoxSelection]} />
                      </MultiSelectComponent>
                    </div>
                  </div>
                  <div className="col-row">
                    <div className="col-left">
                      <label style={{ lineHeight: "34px", margin: "0" }}>
                        First Day of Week
                      </label>
                    </div>
                    <div className="col-right">
                      <DropDownListComponent
                        id="weekFirstDay"
                        dataSource={weekDays}
                        fields={{ text: "text", value: "value" }}
                        value={0}
                        popupHeight={400}
                        change={(args: ChangeEventArgs) => {
                          scheduleObj.current.firstDayOfWeek =
                            args.value as number;
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-row">
                    <div className="col-left">
                      <label style={{ lineHeight: "34px", margin: "0" }}>
                        Work week
                      </label>
                    </div>
                    <div className="col-right">
                      <MultiSelectComponent
                        id="workWeekDays"
                        cssClass="schedule-workweek"
                        ref={(workWeek: MultiSelectComponent) =>
                          (workWeekObj = workWeek)
                        }
                        dataSource={weekDays}
                        mode="CheckBox"
                        fields={{ text: "text", value: "value" }}
                        enableSelectionOrder={false}
                        showClearButton={false}
                        showDropDownIcon={true}
                        value={[1, 2, 3, 4, 5]}
                        change={(args: MultiSelectChangeEventArgs) =>
                          (scheduleObj.current.workDays =
                            args.value as number[])
                        }
                      >
                        <Inject services={[CheckBoxSelection]} />
                      </MultiSelectComponent>
                    </div>
                  </div>
                  <div className="col-row">
                    <div className="col-left">
                      <label style={{ lineHeight: "34px", margin: "0" }}>
                        Timezone
                      </label>
                    </div>
                    <div className="col-right">
                      <DropDownListComponent
                        id="timezone"
                        dataSource={timezoneData}
                        fields={{ text: "text", value: "value" }}
                        value="Etc/GMT"
                        popupHeight={150}
                        change={(args: ChangeEventArgs) => {
                          scheduleObj.current.timezone = args.value as string;
                          updateLiveTime();
                          (
                            document.querySelector(
                              ".schedule-overview #timezoneBtn"
                            ) as HTMLElement
                          ).innerHTML =
                            '<span class="e-btn-icon e-icons e-time-zone e-icon-left"></span>' +
                            args.itemData.text;
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-row">
                    <div className="col-left">
                      <label style={{ lineHeight: "34px", margin: "0" }}>
                        Day Start Hour
                      </label>
                    </div>
                    <div className="col-right">
                      <TimePickerComponent
                        id="dayStartHour"
                        showClearButton={false}
                        value={new Date(new Date().setHours(0, 0, 0))}
                        change={(args: TimeEventArgs) =>
                          (scheduleObj.current.startHour = intl.formatDate(
                            args.value as Date,
                            { skeleton: "Hm" }
                          ))
                        }
                      />
                    </div>
                  </div>
                  <div className="col-row">
                    <div className="col-left">
                      <label style={{ lineHeight: "34px", margin: "0" }}>
                        Day End Hour
                      </label>
                    </div>
                    <div className="col-right">
                      <TimePickerComponent
                        id="dayEndHour"
                        showClearButton={false}
                        value={new Date(new Date().setHours(23, 59, 59))}
                        change={(args: TimeEventArgs) =>
                          (scheduleObj.current.endHour = intl.formatDate(
                            args.value as Date,
                            { skeleton: "Hm" }
                          ))
                        }
                      />
                    </div>
                  </div>
                  <div className="col-row">
                    <div className="col-left">
                      <label style={{ lineHeight: "34px", margin: "0" }}>
                        Work Start Hour
                      </label>
                    </div>
                    <div className="col-right">
                      <TimePickerComponent
                        id="workHourStart"
                        showClearButton={false}
                        value={new Date(new Date().setHours(9, 0, 0))}
                        change={(args: TimeEventArgs) =>
                          (scheduleObj.current.workHours.start =
                            intl.formatDate(args.value as Date, {
                              skeleton: "Hm",
                            }))
                        }
                      />
                    </div>
                  </div>
                  <div className="col-row">
                    <div className="col-left">
                      <label style={{ lineHeight: "34px", margin: "0" }}>
                        Work End Hour
                      </label>
                    </div>
                    <div className="col-right">
                      <TimePickerComponent
                        id="workHourEnd"
                        showClearButton={false}
                        value={new Date(new Date().setHours(18, 0, 0))}
                        change={(args: TimeEventArgs) =>
                          (scheduleObj.current.workHours.end = intl.formatDate(
                            args.value as Date,
                            { skeleton: "Hm" }
                          ))
                        }
                      />
                    </div>
                  </div>
                  <div className="col-row">
                    <div className="col-left">
                      <label style={{ lineHeight: "34px", margin: "0" }}>
                        Slot Duration
                      </label>
                    </div>
                    <div className="col-right">
                      <DropDownListComponent
                        id="slotDuration"
                        dataSource={majorSlotData}
                        fields={{ text: "Name", value: "Value" }}
                        value={60}
                        popupHeight={150}
                        change={(args: ChangeEventArgs) => {
                          scheduleObj.current.timeScale.interval =
                            args.value as number;
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-row">
                    <div className="col-left">
                      <label style={{ lineHeight: "34px", margin: "0" }}>
                        Slot Interval
                      </label>
                    </div>
                    <div className="col-right">
                      <DropDownListComponent
                        id="slotInterval"
                        dataSource={minorSlotData}
                        value={2}
                        popupHeight={150}
                        change={(args: ChangeEventArgs) => {
                          scheduleObj.current.timeScale.slotCount =
                            args.value as number;
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-row">
                    <div className="col-left">
                      <label style={{ lineHeight: "34px", margin: "0" }}>
                        Time Format
                      </label>
                    </div>
                    <div className="col-right">
                      <DropDownListComponent
                        id="timeFormat"
                        dataSource={timeFormatData}
                        fields={{ text: "Name", value: "Value" }}
                        value={"hh:mm a"}
                        popupHeight={150}
                        change={(args: ChangeEventArgs) => {
                          scheduleObj.current.timeFormat = args.value as any;
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-row">
                    <div className="col-left">
                      <label style={{ lineHeight: "34px", margin: "0" }}>
                        Week Numbers
                      </label>
                    </div>
                    <div className="col-right">
                      <DropDownListComponent
                        id="weekNumber"
                        dataSource={weekNumberData}
                        fields={{ text: "Name", value: "Value" }}
                        value={"Off"}
                        popupHeight={150}
                        change={(args: ChangeEventArgs) => {
                          if (args.value == "Off") {
                            scheduleObj.current.showWeekNumber = false;
                          } else {
                            scheduleObj.current.showWeekNumber = true;
                            scheduleObj.current.weekRule = args.value as any;
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-row">
                    <div className="col-left">
                      <label style={{ lineHeight: "34px", margin: "0" }}>
                        Tooltip
                      </label>
                    </div>
                    <div className="col-right">
                      <DropDownListComponent
                        id="tooltip"
                        dataSource={tooltipData}
                        fields={{ text: "Name", value: "Value" }}
                        value={"Off"}
                        popupHeight={150}
                        change={(args: ChangeEventArgs) => {
                          if (args.value === "Off") {
                            scheduleObj.current.eventSettings.enableTooltip =
                              false;
                          } else {
                            scheduleObj.current.eventSettings.enableTooltip =
                              true;
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Agenda_deposito;
